var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('common.categories'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('label',{staticClass:"col-form-label col-sm-3"},[_vm._v(" "+_vm._s(_vm.$t("pages.product.enterCategoryName"))+" ")]),_c('div',{staticClass:"col-sm-9"},[_c('multiselect',{attrs:{"options":_vm.categoryOptions,"loading":_vm.isLoadingCategoryOptions,"searchable":true,"close-on-select":true,"clear-on-select":false,"multiple":true,"placeholder":_vm.$t('common.typetosearch'),"noOptionsText":_vm.$t('common.theListIsEmpty'),"reset-after":false,"local-search":false,"internal-search":false,"id":"ajax","label":"name","track-by":"id"},on:{"search-change":_vm.asyncFindCategory},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})],1)])]),_c('CCol',{attrs:{"md":"6"}})],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.allCategories,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoadingCategory,"items-per-page-select":{
                            label : 'Số lượng',
                            values : [10,20,50,100]
                            },"items-per-page":_vm.itemsPerPage,"pagination":""},on:{"pagination-change":_vm.onItemsPerPageChange},scopedSlots:_vm._u([(_vm.allCategories != null && _vm.allCategories.length > 0)?{key:"footer",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":5}},[_c('span',[_vm._v("Tổng số")]),_c('span',{staticStyle:{"padding-left":"5px","color":"crimson","font-weight":"bold"}},[_vm._v(_vm._s(_vm.allCategories == null ? 0: _vm.allCategories.length))]),_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v("danh mục")])])])])]},proxy:true}:null,{key:"category_detail_actions",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onEdit(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]),_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDelete(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}},{key:"showOnHomePage",fn:function(ref){
                            var item = ref.item;
return [_c('td',[(item.showOnHomepage)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cilCheckAlt"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cilXCircle"}})],1)]}},{key:"includeInTopMenu",fn:function(ref){
                            var item = ref.item;
return [_c('td',[(item.includeInTopMenu)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cilCheckAlt"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cilXCircle"}})],1)]}},{key:"published",fn:function(ref){
                            var item = ref.item;
return [_c('td',[(item.published)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cilCheckAlt"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cilXCircle"}})],1)]}}],null,true)})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }