<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.categories')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">                              
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">
                                        {{ $t("pages.product.enterCategoryName") }}
                                    </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="categoryOptions"
                                                     v-model="selectedCategories"
                                                     :loading="isLoadingCategoryOptions"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     @search-change="asyncFindCategory"
                                                     :internal-search="false"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6">
                                
                            </CCol>
                        </CRow>
                    </CForm>

                    
                </template>
                <template v-slot:list>
                    <CDataTable :items="allCategories"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoadingCategory"
                                :items-per-page-select="{
                                label : 'Số lượng',
                                values : [10,20,50,100]
                                }"
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                pagination>
                        <template #footer v-if="allCategories != null && allCategories.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="5">
                                        <span>Tổng số</span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allCategories == null ? 0: allCategories.length}}</span><span style="padding-left:5px">danh mục</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>

                        <template #category_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEdit(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDelete(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                        <template #showOnHomePage="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.showOnHomepage" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>
                        <template #includeInTopMenu="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.includeInTopMenu" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>
                        <template #published="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.published" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import i18n from '@/plugins/i18n'
 	import Confirmation from '@/components/Confirmation.vue'
    import { mapGetters } from 'vuex'
    import ListForm from '@/components/ListForm.vue'
    
    export default {
		name: 'CategoryList',
		data() {            
            return {
                categoryOptions: [],
                isLoadingCategoryOptions: false,               
				itemsPerPage: 10,
                warningModal: false,
                selectedCategories: null,
                 //warningModalMessage: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod",             
                fields: [
                    { key: 'hierarchyName', label: i18n.t('common.name') },
                    { key: 'showOnHomePage', label: i18n.t('common.showOnHomepage') },
                    { key: 'includeInTopMenu', label: i18n.t('common.includeInTopMenu') },
                    { key: 'published', label: i18n.t('common.published') },
                    {
                        key: 'category_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
		},
        components: { Confirmation, ListForm },
        computed: {            
            ...mapGetters('category', ['isLoadingCategory', 'allCategories'])
        },
		methods: {			
            async onDelete(item) {
                this.$refs.confirmation.show(i18n.t('pages.product.confirmDeleteCategory'), async () => {
                    console.log('Delete category', item);
                    var retVal = await this.$store.dispatch("category/deleteCategory", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
            onEdit(item) {
                console.log("onEditCategory", item);
                this.$router.push('/products/editcategory/' + item.id);
            },
            onSearch() {
                console.log('start search categories');
                
                let categoryIds = [];
                if (this.selectedCategories != null && this.selectedCategories.length > 0)
                    categoryIds = this.selectedCategories.map(item => item.id);

                this.$store.dispatch("category/searchCategories", { categoryIds });
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('categoryItemsPerPage', item)
            },
            async asyncFindCategory(query, id) {
                if (query.length > 1) {
                    this.isLoadingCategoryOptions = true;
                    var result = await this.$categoryService.searchByName(query);
                    this.isLoadingCategoryOptions = false;
                    this.categoryOptions = result;
                }
            },
		},
        mounted() {
            let categoryItemsPerPage = parseInt(localStorage.getItem('categoryItemsPerPage'));
            if (isNaN(categoryItemsPerPage))
                categoryItemsPerPage = 10;
            this.itemsPerPage = categoryItemsPerPage;

            this.onSearch();
            console.log("REFS", this.$refs);
        }
	}
</script>